// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-courses-js": () => import("./../../../src/pages/courses.js" /* webpackChunkName: "component---src-pages-courses-js" */),
  "component---src-pages-dev-404-page-js": () => import("./../../../src/pages/dev-404-page.js" /* webpackChunkName: "component---src-pages-dev-404-page-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machine-gun-days-js": () => import("./../../../src/pages/Machine-Gun-Days.js" /* webpackChunkName: "component---src-pages-machine-gun-days-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-course-post-js": () => import("./../../../src/templates/course-post.js" /* webpackChunkName: "component---src-templates-course-post-js" */),
  "component---src-templates-page-post-js": () => import("./../../../src/templates/page-post.js" /* webpackChunkName: "component---src-templates-page-post-js" */)
}

